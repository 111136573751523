<template>
  <div>
    <el-button
      v-show="role === 'SYSTEM_MANAGER'"
      size="mini"
      type="primary"
      class="setting-btn"
      @click="addModelDialogShow(false)"
      ><i class="iconfont icon-add"></i>&nbsp;&nbsp;{{
        $t("personal.Largelanguage.btn")
      }}</el-button
    >
    <el-tabs v-model="activeName">
      <el-tab-pane name="myApps">
        <span slot="label">
          {{ $t("personal.Largelanguage.title") }}
        </span>
        <div class="card-container">
          <div v-for="(item, index) in modelList" :key="index" class="card-box">
            <div v-show="item.model === 'deepseek-chat'" class="recommend-tag">
              {{ $t("certificate.Recommend") }}
            </div>
            <div class="card-main">
              <div class="card-title">
                <personal-avatar
                  :avatar="item.icon"
                  :nickname="item.name"
                  :size="60"
                  :tool-tip-disabled="true"
                  style="margin: 20px 20px 20px 10px"
                ></personal-avatar>
                <span class="card-name">{{ item.name }}</span>
                <span
                  class="card-config"
                  :class="item.baseUrl ? 'card-configed' : 'card-notConfiged'"
                >
                  {{
                    item.baseUrl
                      ? $t("personal.Largelanguage.configured")
                      : $t("personal.Largelanguage.notconfigured")
                  }}
                </span>
                <div v-if="item.custom" @click="deleteModel(item)">
                  <i
                    class="el-icon-close"
                    style="
                      font-size: 26px;
                      color: #b6b7ba;
                      cursor: pointer;
                      margin-left: 6px;
                    "
                  ></i>
                </div>
              </div>
              <div class="card-des">
                <el-tooltip :content="item.description">
                  <span>{{ item.description }}</span></el-tooltip
                >
              </div>
              <div class="card-tag">
                <span>
                  {{ item.modelCapability }}
                </span>
              </div>
            </div>
            <div v-show="role === 'SYSTEM_MANAGER'" class="card-options">
              <div>
                <span>{{ $t("personal.thirdPartyManagement.config") }}</span>
                <i
                  class="el-icon-s-tools"
                  style="
                    color: #004caa;
                    font-size: 24px;
                    vertical-align: sub;
                    cursor: pointer;
                  "
                  @click="addModelDialogShow(item)"
                ></i>
              </div>
              <div>
                <span>{{ $t("fileType.enableOrNot") }}</span>
                <el-switch
                  v-model="item.enabled"
                  active-color="#004CAA"
                  inactive-color="#E1E3E6"
                  @change="
                    (value) => {
                      enabledFn(value, item.modelConfigId);
                    }
                  "
                >
                </el-switch>
              </div>
              <div>
                <span>{{ $t("personal.Largelanguage.default") }}</span>
                <el-switch
                  v-model="item.defaulted"
                  active-color="#004CAA"
                  inactive-color="#E1E3E6"
                  @change="
                    (value) => {
                      defaultedFn(value, item.modelConfigId);
                    }
                  "
                >
                </el-switch>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :visible.sync="addModelDialog"
      width="25%"
      center
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">
        {{ $t("menu.Largelanguage") }}
        <el-tooltip placement="top" effect="light">
          <div slot="content">
            {{ $t("personal.Largelanguage.tip1") }}
            <a
              href="https://ms.ytdevops.com/pubKnowledge/b91ff831-7b2f-4cfb-9a0a-e9c57f9d679b"
              target="_blank"
              >https://ms.ytdevops.com/pubKnowledge/b91ff831-7b2f-4cfb-9a0a-e9c57f9d679b</a
            >
          </div>
          <i
            id="dddd8"
            class="iconfont icon-help"
            style="
              font-size: 16px;
              margin-left: 6px;
              color: #606266;
              font-weight: normal;
            "
          ></i>
        </el-tooltip>
      </div>
      <div style="position: relative" class="llm-fixed-label">
        <div class="model-logo">
          <div v-if="!addModelRuleForm.icon" class="no-logo">LOGO</div>
          <img v-else :src="addModelRuleForm.icon" />
          <el-upload
            v-if="addModelRuleForm.custom"
            action="#"
            accept=".jpg, .jpeg, .png, .svg"
            :http-request="upload_attachment_handler"
            :show-file-list="false"
          >
            <el-button type="text"
              >{{ $t("homeTopBar.newProjectForm.editBtn") }}
              <i
                class="iconfont icon-branch_edit"
                style="margin-left: 5px; color: #004caa"
              ></i
            ></el-button>
          </el-upload>
        </div>
        <el-form
          :model="addModelRuleForm"
          :rules="addModelRules"
          label-position="left"
          ref="addModelForm"
          label-width="100px"
        >
          <el-form-item
            :label="$t('personal.Largelanguage.configurationname')"
            prop="name"
          >
            <el-input
              :disabled="!addModelRuleForm.custom"
              v-model="addModelRuleForm.name"
              style="width: 60%"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('personal.Largelanguage.des')"
            prop="description"
          >
            <el-input
              v-model="addModelRuleForm.description"
              type="textarea"
              :rows="2"
              style="width: 60%"
              :disabled="!addModelRuleForm.custom"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('personal.Largelanguage.Modelprovider')"
            prop="modelProvider"
          >
            <el-select
              v-model="addModelRuleForm.modelProvider"
              placeholder=""
              style="width: 100%"
              :disabled="!addModelRuleForm.custom"
              @change="changeModelProvider"
            >
              <el-option
                v-for="(item, index) in Object.keys(providerMap)"
                :key="index"
                :label="
                  item === 'CUSTOM'
                    ? $t('personal.Largelanguage.CustomModel')
                    : item
                "
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('personal.Largelanguage.modelversion')"
            prop="modelVersion"
          >
            <el-select
              v-show="addModelRuleForm.modelProvider !== 'CUSTOM'"
              v-model="addModelRuleForm.modelVersion"
              placeholder=""
              style="width: 100%"
              :disabled="!addModelRuleForm.custom"
              @change="changeModelVersion"
            >
              <el-option
                v-for="(item, index) in versionList"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <el-input
              v-show="addModelRuleForm.modelProvider === 'CUSTOM'"
              v-model="addModelRuleForm.modelVersion"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('personal.Largelanguage.Modelcapability')"
            prop="modelCapability"
          >
            <el-select
              v-model="addModelRuleForm.modelCapability"
              placeholder=""
              style="width: 100%"
              :disabled="
                addModelRuleForm.modelProvider !== 'CUSTOM' ||
                !addModelRuleForm.custom
              "
            >
              <el-option label="CHAT" value="CHAT"></el-option>
              <el-option label="IMAGE" value="IMAGE"></el-option>
              <el-option label="EMBEDDING" value="EMBEDDING"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('personal.Largelanguage.modelname')"
            prop="model"
          >
            <el-input
              v-model="addModelRuleForm.model"
              :disabled="
                !addModelRuleForm.custom ||
                addModelRuleForm.modelProvider !== 'CUSTOM'
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="BaseUrl" prop="baseUrl">
            <el-input
              v-model="addModelRuleForm.baseUrl"
              :disabled="
                !addModelRuleForm.custom ||
                addModelRuleForm.modelProvider !== 'CUSTOM'
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="ApiKey" prop="apiKey">
            <el-input
              v-model="addModelRuleForm.apiKey"
              :disabled="!addModelRuleForm.custom"
              auto-complete="new-password"
              type="password"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div
        v-if="addModelRuleForm.custom"
        slot="footer"
        style="text-align: right"
      >
        <el-button type="info" @click="cancelFn"
          >{{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button
          type="primary"
          size="small"
          class="button-confirm"
          @click="confirmFn"
          >{{ $t("personal.Largelanguage.Testandsave") }}
        </el-button>
      </div>
    </el-dialog>
    <payDialog
      :dialog-visible.sync="payDialog_isShow"
      :title_text="$t('personal.Largelanguage.tip1')"
      :tip_text="$t('personal.Largelanguage.tip2')"
    ></payDialog>
  </div>
</template>

<script>
import {
  getAllLLM,
  addLLM,
  editLLM,
  presetLLM,
  enabledLLM,
  defaultedLLM,
  deleteLLM,
} from "@/network/llm";
import { get_component_license } from "@/network/license/index.js";
import { ossAuthorization } from "@/utils/oss";
import payDialog from "@/components/payFeatureValidDialog";
import PersonalAvatar from "@/components/personal";
export default {
  components: {
    payDialog,
    PersonalAvatar,
  },
  computed: {
    versionList() {
      if (this.addModelRuleForm.modelProvider) {
        return Object.keys(
          this.providerMap[this.addModelRuleForm.modelProvider]
        ).filter((item) => {
          return item;
        });
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      activeName: "myApps",
      addModelDialog: false,
      modelList: [],
      addModelRuleForm: {},
      addModelRules: {
        modelName: [{ required: true }],
        baseUrl: [{ required: true }],
        modelProvider: [{ required: true }],
        modelVersion: [{ required: true }],
        modelCapability: [{ required: true }],
      },
      role: "",
      providerMap: {},
      payDialog_isShow: false,
    };
  },
  mounted() {
    this.role = JSON.parse(localStorage.getItem("store")).user.roles
      ? JSON.parse(localStorage.getItem("store")).user.roles[0].name
      : "NORMAL_USER";
    this.get_component_license();
  },
  methods: {
    deleteModel(item) {
      this.$confirm(
        this.$t("chartTop.sureDelete"),
        this.$t("nodeDetail.third.warn"),
        {
          confirmButtonText: this.$t("btn.deleteBtn"),
          confirmButtonClass: "delete_button_class",
          cancelButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "delete_cancel_class",
          type: "warning",
        }
      )
        .then(() => {
          const params = {
            modelConfigId: item.modelConfigId,
          };
          deleteLLM(params).then((res) => {
            this.getAllLLM();
          });
        })
        .catch(() => {});
    },
    changeModelProvider() {
      this.addModelRuleForm.baseUrl = "";
      this.addModelRuleForm.modelCapability = "";
      this.addModelRuleForm.model = "";
      this.addModelRuleForm.modelVersion = "";
    },
    get_component_license() {
      get_component_license().then((res) => {
        const licenseList = [];
        for (let item of res) {
          if (item.effective) {
            licenseList.push(item.feature);
          }
        }
        if (licenseList.indexOf("AI_ROBOT") !== -1) {
          getAllLLM().then((res) => {
            this.modelList = res;
            this.presetLLM();
          });
        } else {
          this.payDialog_isShow = true;
        }
      });
    },
    enabledFn(value, id) {
      const parmas = {
        modelConfigId: id,
      };
      enabledLLM(parmas).then((res) => {
        this.getAllLLM();
      });
    },
    defaultedFn(value, id) {
      const parmas = {
        modelConfigId: id,
      };
      defaultedLLM(parmas).then((res) => {
        this.getAllLLM();
      });
    },
    changeModelVersion() {
      let resetData =
        this.providerMap[this.addModelRuleForm.modelProvider][
          this.addModelRuleForm.modelVersion
        ];
      this.addModelRuleForm.baseUrl = resetData.baseUrl;
      this.addModelRuleForm.modelCapability = resetData.capability;
      this.addModelRuleForm.model = resetData.model;
    },
    presetLLM() {
      presetLLM().then((res) => {
        res.forEach((provider) => {
          const obj = {};
          provider.modelVersionDtoList.forEach((version) => {
            obj[version.version] = version;
          });
          this.providerMap[provider.modelProvider] = obj;
        });
      });
    },
    getAllLLM() {
      getAllLLM().then((res) => {
        this.modelList = res;
      });
    },
    async upload_attachment_handler(params) {
      const self = this;
      let file = params.file;
      await ossAuthorization.call(self);
      self.client.put(`llmIcon/${file.name}`, file).then((ossres) => {
        this.addModelRuleForm.icon = ossres.url;
      });
    },
    addModelDialogShow(data) {
      if (data) {
        this.addModelRuleForm = JSON.parse(JSON.stringify(data));
      } else {
        this.addModelRuleForm = {
          name: "",
          model: "",
          description: "",
          modelProvider: "",
          modelVersion: "",
          modelCapability: "",
          baseUrl: "",
          apiKey: "",
          icon: "",
          custom: true,
        };
      }
      this.addModelDialog = true;
    },
    confirmFn() {
      this.$refs["addModelForm"].validate((valid) => {
        if (valid) {
          if (this.addModelRuleForm.modelConfigId) {
            const params = {
              modelConfigId: this.addModelRuleForm.modelConfigId,
              data: this.addModelRuleForm,
            };
            editLLM(params).then((res) => {
              this.getAllLLM();
              this.addModelDialog = false;
            });
          } else {
            const params = {
              data: this.addModelRuleForm,
            };
            addLLM(params).then((res) => {
              this.getAllLLM();
              this.addModelDialog = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    cancelFn() {
      this.addModelDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-btn {
  position: absolute;
  left: 90%;
  z-index: 1;
}
.card-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 80vh;
  padding-bottom: 20px;
  overflow-y: scroll;
  .card-box {
    width: 31%;
    height: 258px;
    margin-right: 2%;
    margin-top: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    .recommend-tag {
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
      height: 32px;
      line-height: 32px;
      width: 23%;
      text-align: center;
      text-indent: 1em;
      background-image: url("../..//assets/img/recommendtag.png"); /* 使用 background-image */
      background-size: cover;
      border-radius: 0 4px 0 0;
    }
    .card-main {
      padding: 0 20px;
      height: 200px;
      background: linear-gradient(
        135deg,
        rgba(240, 245, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      .card-title {
        display: flex; /* 使用 flexbox 布局 */
        align-items: center;
        img {
          vertical-align: middle;
          width: 60px;
          height: 60px;
          margin: 20px 20px 20px 10px;
        }
        .card-name {
          font-size: 18px;
          color: #383838;
        }
        .card-config {
          padding: 4px;
          border-radius: 2px;
          margin-left: auto;
        }
        .card-configed {
          color: rgba(67, 207, 124, 1);
          background-color: rgba(67, 207, 124, 0.2);
        }
        .card-notConfiged {
          color: #b6b7ba;
          background-color: rgba(182, 183, 186, 0.2);
        }
      }
      .card-des {
        font-size: 16px;
        color: rgba(56, 56, 56, 100);
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: left;
        }
      }
      .card-tag {
        display: flex;
        margin-top: 10px;
        & > span {
          display: inline-block;
          padding: 4px;
          margin-right: 6px;
          border: 1px solid #b6b7ba;
          color: rgba(182, 183, 186, 1);
          border-radius: 2px;
        }
      }
    }
    .card-options {
      display: flex;
      height: 50px;
      align-items: center;
      & > div {
        width: 33%;
        position: relative;
        height: 50px;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
          content: "";
          display: inline-block;
          height: 40px;
          top: 5px;
          width: 1px;
          background: rgba(240, 242, 245, 1);
          position: absolute;
          right: 0;
        }
        &:last-of-type {
          &::before {
            display: none;
          }
        }
        & > span {
          font-size: 16px;
          color: rgba(56, 56, 56, 1);
          margin-right: 20px;
        }
      }
    }
  }
}
.model-logo {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  width: 30%;
  justify-content: center;
  z-index: 99;
  .no-logo {
    width: 8vh;
    height: 8vh;
    border-radius: 50%;
    background-color: #b6b7ba;
    color: #fff;
    text-align: center;
    line-height: 8vh;
    font-size: 20px;
    font-weight: bold;
  }
  img {
    width: 8vh;
    height: 8vh;
    border-radius: 50%;
  }
}
</style>
<style lang="scss">
.llm-fixed-label {
  .el-form {
    .el-form-item__label {
      word-break: normal !important;
      word-wrap: normal !important;
    }
  }
}
</style>
